@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400&display=swap');
*{
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    padding: 0;
}
html,body {
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #212529;
}
