@import url("https://fonts.googleapis.com/css2?family=Rajdhani:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@100&display=swap");
.sidenav {
    height: 100%;
    width: 3.2rem;
    user-select: none;
    min-height: 100vh;
    max-height: 100vh;
    background: linear-gradient(135deg, #37383f, #3c3e48);
    backdrop-filter: blur(10px);
    --webkit-backdrop-filter: blur(10px);
    border-radius: 0px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
}

.sidenav > nav {
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: space-between;
}

.iconsHolder > div {
    width: 105%;
    margin-top: 0.5rem;
    height: 4.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0.2rem;
    transition: 0.5s ease;
    font-size: 0.8rem;
    text-align: center;
}
.iconsHolder > div:hover {
    background-color: #06113c;
}

a {
    width: 105% !important;
    color: inherit !important;
    text-decoration: inherit !important;
    margin-top: 0.5rem !important;
    height: 4.5rem !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    cursor: pointer !important;
    padding: 0.2rem !important;
    transition: 0.5s ease !important;
    font-size: 0.86rem !important;
}

.active {
    background-color: #55575e;
}

/* about me */
.aboutmecontainer {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 1.1fr 1fr;
    user-select: none;
}
.textSection {
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: rgb(255, 255, 255);
    margin-top: 2rem;
    margin-left: 0.5rem;
    margin-bottom: 1rem;
}
.textSection > p {
    padding-bottom: 1rem;
}
.cvButton {
    width: 10rem;
    height: 3rem;
    color: rgb(196, 191, 191);
    margin: auto;
    outline: none;
    background-color: inherit;
    border: 1px solid #3a5fbc;
    font-size: 1.2rem;
    cursor: pointer;
    background-color: #3a5fbcb3;
}
.hi {
    font-size: 1.3rem;
    margin-top: 0.8rem;
}
.name {
    font-size: 3rem;
    color: #4266c0;
    font-family: "Rajdhani", sans-serif;
}
.textSection > p {
    width: 90%;
    margin: auto;
    font-size: 1.4rem;
    margin-top: 0.8rem;
    font-family: "Barlow Condensed", sans-serif;
}
.imageSection {
    margin: auto;
    width: 20rem;
}
.imageSection > img {
    width: 100%;
    object-fit: cover;
    margin-bottom: 0;
    border-radius: 10px;
}

@keyframes anim {
    to {
        margin-top: 0rem;
    }
}

/*                  responsive            */
@media only screen and (min-width: 600px) {
    .rightSection {
        margin: auto;
    }
    .leftSection {
        padding-left: 1rem;
    }
    .aboutmecontainer {
        display: flex;
        height: 100vh;
        align-items: center;
    }
    .textSection {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .hi {
        font-size: 1.8rem;
        margin-bottom: 1.5rem;
    }
    .name {
        font-size: 2.5rem;
        margin-bottom: 1rem;
    }
    .textSection > p {
        margin: 0;
        margin: 1.6rem auto;
        font-size: 1.6rem;
    }

    .imageSection {
        height: 25rem;
        display: flex;
    }
    .imageSection > img {
        height: 100%;
    }
    .cvButton {
        width: 10rem;
        height: 3rem;
        color: rgb(196, 191, 191);
        margin: auto;
        outline: none;
        background-color: inherit;
        border: 1px solid #3a5fbc;
        font-size: 1.2rem;
        cursor: pointer;
        transition: background-color 0.4s ease;
    }
    .cvButton:hover {
        background-color: #204194;
        /* background-image: linear-gradient(to bottom, #4266c0, #16af93); */
    }
}

/*  Skills       */
.skillHolder {
    width: 100%;
}
.heading,
.contacttext,
.mailme > p {
    color: #cdd1e0;
    font-family: "Rajdhani", sans-serif;
    display: flex;
    font-size: 3.5rem;
    padding-left: 0.4rem;
}
.progHolder {
    height: 80vh;
    width: 80%;
    margin: auto auto;
    display: grid;
    gap: 4rem;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    justify-content: center;
    overflow-y: scroll;
}
.progHolder::-webkit-scrollbar{
    width: 0px;
}
.prog {
    width: 13.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1.7rem;
    background-color: #ffffff15;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.skillimageholder{
    padding : 1rem;
}
.langdetail{
    height: 60%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: #afb2bc; */
    background-image: linear-gradient(to left, #a2cdce, #98a0cfe6, #bde6c8ba);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: .5rem;
}
/* .prog > .progress {
    margin-left: 1rem;
}
.progress {
    width: 90%;
}

.progress-bar {
    font-size: 0.8rem;
} */

.prog img {
    height: 2.5rem;
}

/*         responsive         */
@media only screen and (min-width: 600px) {
}

/*         contact          */
.wholeContact {
    color: rgb(10, 134, 188);
}

.contactContainer {
    margin-top: 5%;
}
.socialMedias {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
    width: 95%;
    margin: auto;
    margin-top: 1.5rem;
}
.socialMedias > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgb(214, 210, 210);
}
.imgholder {
    height: 3rem;
    cursor: pointer;
}
.imgholder > img {
    height: 3rem;
    object-fit: cover;
}

.mailMeHolder {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.mailMeHolder > p {
    margin-top: 4rem;
}

.mailMeHolder > input {
    width: 90%;
    margin: 1rem auto;
    resize: none;
    outline: none;
    background-color: inherit;
    border: 0px;
    border-bottom: 1px solid coral;
}
.mailMeHolder > input:focus {
    border-bottom: 1px solid rgb(9, 118, 149);
}
.sumbitBtn {
    margin: auto;
    margin-top: 1rem;
    width: 50%;
}

@media screen and (min-width: 840px) {
    .progHolder {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media screen and (max-width: 840px) {
    .progHolder {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media screen and (max-width: 600px) {
    .progHolder {
        grid-template-columns: repeat(1, 1fr);
    }
}
@media screen and (min-width: 600px) {
    .mailme > p {
        margin-top: 1.5rem;
    }
    .mailme {
        margin: auto;
    }
    .sumbitBtn {
        width: 30%;
    }
    .mailMeHolder > input {
        width: 60%;
    }
    .contactContainer {
        /* margin: auto; */
        margin-top: 1rem;
    }
  
}

/*  Work     */
.worksPage {
    height: 99vh;
    width: 90%;
    margin: auto;
    overflow-y: scroll;
}
.workHolder {
    margin-top: 1rem;
    margin-bottom: 3.8rem;
    background-color: #afb2bc;
    padding-bottom: 0.4rem;
    border-radius: 0 0 10px 10px;
    /* box-shadow: 1px 1px 5px white; */
}
.workImageContainer {
    height: 10rem;
}

.workImageContainer > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.title {
    text-align: center;
    font-size: 1.3rem;
    font-weight: 600;
}
.workIconHolder {
    display: flex;
    width: 80%;
    justify-content: space-around;
    margin: auto;
}
.workIconHolder > div {
    cursor: pointer;
}

@media screen and (min-width: 600px) {
    .worksPage {
        overflow-y: scroll;
        width: 100vw;
        user-select: none;
    }

    .allWorks {
        width: 80%;
        margin: auto;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 2rem;
    }
    .workHolder:hover {
        box-shadow: 1px 1px 6px rgb(2, 148, 148);
    }
    .workImageContainer {
        cursor: pointer;
    }
}

div[role="progressbar"] {
    background-image: linear-gradient(to left, #ae6770, #445e80, #298ca4);
}


.sticky{
    width: 100%;
    background-color: #212529;
    position: sticky;
    top:0;
}